import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Main",
    component: () => import("@/views/main/Index.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/home/Index.vue"),
      },
      // 合作商户
      {
        path: "/cooperative/cooperative_home",
        name: "CooperativeHome",
        component: () => import("@/views/cooperative/CooperativeHome.vue"),
      },
      // 关于分蛋
      {
        path: "/about/aboutfd_home",
        name: "AboutfdHome",
        component: () => import("@/views/about/AboutfdHome.vue"),
      },
      // 权威认证点击详情1
      {
        path: "/activityinfo/activityinfo1",
        name: "Activityinfo1",
        component: () => import("@/views/activityinfo/Activityinfo1.vue"),
      },
      // 投资者关系
      {
        path: "/invest/invest_home",
        name: "InvestHome",
        component: () => import("@/views/invest/InvestHome.vue"),
      },



      // // 产品
      // {
      //   // 数字婚嫁(婚嫁互娱)
      //   path: "/product/digital_marriage",
      //   name: "DigitalMarriage",
      //   component: () => import("@/views/product/DigitalMarriage.vue"),
      // },
      // {
      //   // 数字财税(小型企业SaaS管理云,初创企业SaaS管理云,小微企业管理上云)
      //   path: "/product/finance_tax",
      //   name: "FinanceTax",
      //   component: () => import("@/views/product/FinanceTax.vue"),
      // },
      // {
      //   // 云产品(云产品)
      //   path: "/product/cloud_product",
      //   name: "CloudProduct",
      //   component: () => import("@/views/product/CloudProduct.vue"),
      // },
      // // 解决方案
      // {
      //   // 消费金融解决方案(消费金融)
      //   path: "/solution/consumer_finance",
      //   name: "ConsumerFinance",
      //   component: () => import("@/views/solution/ConsumerFinance.vue"),
      // },
      // {
      //   // 婚嫁解决方案(婚礼堂，婚嫁互娱)
      //   path: "/solution/marriage",
      //   name: "Marriage",
      //   component: () => import("@/views/solution/Marriage.vue"),
      // },
      // {
      //   // 零售解决方案(餐饮零售)
      //   path: "/solution/retail",
      //   name: "Retail",
      //   component: () => import("@/views/solution/Retail.vue"),
      // },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
