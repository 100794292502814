<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// 根标签文字大小 =  当前设备宽度 / 基数
function setFontSize() {
  // 确定基数
  const i = 80;
  // 获取设备
  const htmlClient = document.documentElement;
  // 设备宽度
  let htmlWidth = htmlClient.offsetWidth;
  // 设备宽度不能无限的大不能无限小 (750 - 2440)
  if (htmlWidth >= 2440) {
    htmlWidth = 2440;
  } else if (htmlWidth <= 750) {
    htmlWidth = 750;
  }
  // 设置根标签文字大小
  htmlClient.style.fontSize = htmlWidth / i + "px";
}
// 调用函数
setFontSize();
// 当窗口大小发生改变的时候
window.onresize = function () {
  setFontSize();
};
</script>
<style lang="scss">
@import "@/assets/css/common.scss";
// @font-face {
//   font-family: "MySong";
//   src: url("https://static.91fd.com/fonts/SourceHanSerifSC/SourceHanSerifSC-Regular.otf");
// }
#app {
  min-width: 750px;
  max-width: 2440px;
  margin: auto;
  // font-family: fangsong;
  // font-family: cursive;
  // font-family:"newfangsong", "SimSun", "SimSun-ExtB", "STSong", serif;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color emoji;
}
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
